import { h } from "preact";
import "leaflet"
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css";
import "leaflet-defaulticon-compatibility";

import { TileLayer, SVGOverlay } from "react-leaflet";
import { MAP_DEFAULTS } from "../../utlis/maputils";

export const MapLayer = () => {
  if (MAP_DEFAULTS.SVGOVERLAY.SHOW) {
    return (
      <SVGOverlay bounds={MAP_DEFAULTS.BOUNDS}>
        <svg>
          <use xlinkHref="#mapmap"></use>
        </svg>
      </SVGOverlay>
    );
  }
  
  return (
    <TileLayer
      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
  );
};
