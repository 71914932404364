import { h } from "preact";
import { Link } from "preact-router";

export const StationPopup = ({ station, tours }) => {
  const tour = tours.find(
    (tour) => tour.stationsSorted.find((s) => s.station.id == station.id) && tour?.id
  );
  
  const href = tour 
    ? `/tour/${tour.id}/station/${station.id}`
    : `/station/${station.id}`;

  return (
    <Link href={href}>
      <p>{station.name_external}</p>
    </Link>
  );
};
