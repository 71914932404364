import configData from "../../config.json";
import { Link } from "preact-router";

const Sidebar = (props) => {
  return (
    <aside class="sidebar is-open">
      <div class="sidebar__head">
        <Link href="#" onClick={() => props.toggleSidebar()}>
          <svg class="icon icon-close icon--small">
            <use xlinkHref="#icon-close"></use>
          </svg>
        </Link>
        {props.homepage?.headerImage && (
          <img
            src={`${configData.BACKEND_URL}${props.homepage.headerImage.url}`}
            alt="Header Image"
          />
        )}
        <Link href="/" onClick={() => props.toggleSidebar()}>
          {props.homepage?.showHeaderLogo ? (
            props.homepage.logo ? (
              <div class="icon icon-logo">
                <img
                  src={`${configData.BACKEND_URL}${props.homepage.logo.url}`}
                  alt="Logo"
                />
              </div>
            ) : (
              <div class="icon icon-logo">
                <img
                  src={`${configData.BACKEND_URL}/kfn_logo_app_white.svg`}
                  alt="Default Logo"
                />
              </div>
            )
          ) : (
            <div class="icon icon-logo">
              {props.homepage?.logoname || "kinferno"}
            </div>
          )}
        </Link>
      </div>

      <nav class="tour-nav">
        <ul>
          {props.menu?.Menu.map((item,id) => {
            if (item.__component === "menu.link" && item.URL) {
              return (
                <li key={id}>
                  <Link href={item.URL} onClick={() => props.toggleSidebar()}>
                    {item.Name}
                  </Link>
                </li>
              );
            }
            if (item.__component === "menu.contributors") {
              return (
                <li key={id}>
                  <Link href="/contributors" onClick={() => props.toggleSidebar()}>
                    {item.Name}
                  </Link>
                </li>
              );
            }
            if (item.__component === "menu.map") {
              return (
                <li key={id}>
                  <Link href="/map" onClick={() => props.toggleSidebar()}>
                    {item.Name}
                  </Link>
                </li>
              );
            }

            if (item.tours) {
              return (
                <li key={id}>
                  <Link href="/" onClick={() => props.toggleSidebar()}>
                    Audiotouren
                  </Link>
                  <ul>
                    {item.tours.map((tour,idid) => (
                      <li key={idid}>
                        <Link
                          href={`/tour/${tour.id}`}
                          onClick={() => props.toggleSidebar()}
                        >
                          {tour.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              );
            }

            if (item.stations) {
              return (
                <li key={id}>
                  <Link href="/" onClick={() => props.toggleSidebar()}>
                    Audiostationen
                  </Link>
                  <ul>
                    {item.stations.map((station,idid) => (
                      <li key={idid}>
                        <Link
                          href={`/station/${station.id}`}
                          onClick={() => props.toggleSidebar()}
                        >
                          {station.name_external}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              );
            }

          })}
        </ul>
      </nav>

      <nav class="legal-nav">
        <ul>
          <li>
            <Link href="/imprint" onClick={() => props.toggleSidebar()}>
              Impressum
            </Link>
          </li>
          <li>
            <Link href="/privacy" onClick={() => props.toggleSidebar()}>
              Datenschutz
            </Link>
          </li>
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
