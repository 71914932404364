import { h } from "preact";
import { Marker, Popup } from "react-leaflet";
import { StationPopup } from "./StationPopup";
import { createMarkerIcon } from "../../utlis/maputils";

export const StationMarker = ({ station, tours }) => {
  const tour = tours?.find((tour) =>
    tour.stationsSorted.find(
      (stationSorted) => stationSorted.station.id == station.id
    )
  );
  const iconMarkerColored = createMarkerIcon(tour?.color || station.color);

  return (
    station?.location?.marker && (
      <Marker position={station.location.marker} icon={iconMarkerColored}>
        <Popup>
          <StationPopup station={station} tours={tours} />
        </Popup>
      </Marker>
    )
  );
};
