import { h } from "preact";
import { useMap } from "react-leaflet";
import L from "leaflet";
import { MAP_DEFAULTS } from "../../../utlis/maputils";

export const Fly = (props) => {
  const map = useMap();

  if (props.action && props.station && props.isStation) {
    let startmarker = L.marker(props.station.location.marker);
    let nextstation = props.stations.find((s) => s.id == props.station.idNext);
    let nextMarker = nextstation
      ? L.marker(nextstation.location.marker)
      : false;
    if (nextMarker) {
      startmarker = L.featureGroup([startmarker, nextMarker]);
      map.flyToBounds(startmarker.getBounds());
    }
    if (!nextMarker) map.flyTo(props.station.location.marker, MAP_DEFAULTS.ZOOM+2);

    props.setAction(false);
  }

  if (props.action && props.route && !props.noTour) {
    var route = L.geoJSON(props.route);
    map.flyToBounds(route.getBounds());
    props.setAction(false);
  }

  return null;
};
