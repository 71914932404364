import configData from '../config.json';
import "leaflet";

export const MAP_DEFAULTS = {
  ENABLED:configData.MAP.ENABLED || true,  
  CENTER: configData.MAP.CENTER || [50.9397711110769, 11.278152465820312],
  ZOOM: parseFloat(configData.MAP.ZOOM) || 14,
  MAXZOOM: 40,
  SVGOVERLAY: {
    SHOW: configData.MAP.SVGOVERLAY.SHOW || false,
  },
  BOUNDS: configData.MAP.SVGOVERLAY.BOUNDS || [
    [50.94155250461109, 11.275684833526611],
    [50.93676929167634, 11.281124353408813],
  ]
};

const BASE_SVG_URL = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' stroke-linejoin='round' stroke-miterlimit='2' viewBox='0 0 22 31' id='icon-location' version='1.1'%3E%3Cpath d='M10.864 30.59l-.582-.712C9.894 29.36 0 17.785 0 10.865 0 4.851 4.915.001 10.864.001c5.95 0 10.865 4.914 10.865 10.864 0 6.92-9.895 18.56-10.283 19.013l-.582.711z' fill-rule='nonzero' id='path2' style='fill:%23374a86' /%3E%3Cpath d='M10.864 15.004c-2.263 0-4.139-1.876-4.139-4.139 0-2.263 1.876-4.139 4.14-4.139 2.263 0 4.138 1.876 4.138 4.139 0 2.263-1.875 4.139-4.139 4.139zm0-6.726a2.594 2.594 0 00-2.587 2.587 2.594 2.594 0 002.587 2.587 2.594 2.594 0 002.587-2.587 2.594 2.594 0 00-2.587-2.587z' fill-rule='nonzero' id='path4' style='fill:%23ffffff' /%3E%3C/svg%3E";

export const createMarkerIcon = (color) => {
  const url = color
    ? BASE_SVG_URL.replace("374a86", color.replace("#", ""))
    : BASE_SVG_URL;

  return new L.Icon({
    iconUrl: url,
    iconRetinaUrl: url,
    iconAnchor: [20, 50],
    popupAnchor: [0, -50],
    shadowAnchor: [35, 50],
    iconSize: new L.Point(35, 50),
  });
};