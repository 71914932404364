import { useContext, useEffect } from "preact/hooks";
import useAudio from "../useAudio";
import { route } from "preact-router";
import InputRange from "react-input-range";
import "../../css/player.css";
import configData from "../../config.json";

const Player = (props) => {
  const [audioFeatureElement, audioFeatureProps] = useAudio(
    configData.BACKEND_URL +
      props.playerFeature.currentTrack.audiofile.url ||
      configData.PLAYER.FEATURE.FALLBACK_FILE_URL
  );
  const [audioBackgroundElement, audioBackgroundProps] = useAudio(
    configData.BACKEND_URL +
      props.playerBackground.currentTrack.audiofile.url ||
      configData.PLAYER.BACKGROUND.FALLBACK_FILE_URL );

  if (props.playerFeature.isPlaying != audioFeatureProps.isPlaying) {
    //play
    props.playerFeature.isPlaying && !audioFeatureProps.isEnding
      ? audioFeatureProps.setPlaying(true)
      : audioFeatureProps.setPlaying(false);
    //pause at end
    if (!audioFeatureProps.isPlaying && audioFeatureProps.isEnding) {
      props.playerController.pause();
      audioFeatureProps.setEnding(false);
    }
  }

  if (
    (props.playerBackground.isPlaying && props.playerBackground.enabled) !=
    audioBackgroundProps.isPlaying
  ) {
    audioBackgroundProps.setLooping();
    props.playerBackground.isPlaying
      ? audioBackgroundProps.setPlaying(true)
      : audioBackgroundProps.setPlaying(false);
  }

  let nameOfCurrentTrack = props.playerFeature.currentTrack.name;
  let numberOfTracks = props.playingStation.audiofiles.length;
  let indexOfCurrentTrack = props.playingStation.audiofiles.findIndex(
    (a) => a.name == nameOfCurrentTrack
  );
  let numberOfCurrentTrack = indexOfCurrentTrack + 1;
  let firstTrack = false;
  let lastTrack = false;
  if (indexOfCurrentTrack == 0) firstTrack = true;
  if (numberOfTracks == numberOfCurrentTrack) lastTrack = true;

  let durationInMin = Math.floor(audioFeatureProps.duration / 60);
  durationInMin = ("0" + durationInMin).slice(-2);
  let durationInSec = Math.floor(
    audioFeatureProps.duration - durationInMin * 60
  );
  durationInSec = ("0" + durationInSec).slice(-2);

  let currentTimeInMin = Math.floor(audioFeatureProps.currentTime / 60);
  currentTimeInMin = ("0" + currentTimeInMin).slice(-2);
  let currentTimeInSec = Math.floor(
    audioFeatureProps.currentTime - currentTimeInMin * 60
  );
  currentTimeInSec = ("0" + currentTimeInSec).slice(-2);

  let noTour = props.currentStation.noTour;

  return (
    <>
      {props.playerFeature.showPlayer && audioFeatureElement}
      {props.playerFeature.showPlayer &&
        props.playerBackground.matched &&
        audioBackgroundElement}
      <aside class=" player-active player  is-active ">
        <div class="player__buttons">
          {
            props.playerBackground.enabled ?
            (
              <button
                class="button-round button-round--tiny"
                aria-label="Hintergrundgeräusche deaktivieren/aktivieren"
                onClick={() => props.playerController.toggleBackgroundStatus()}
              >
                <svg class="icon icon--blue">
                  <use
                    xlinkHref={
                      props.playerBackground.enabled ? "#icon-mute" : "#icon-unmute"
                    }
                  ></use>
                </svg>
              </button>
            ) :
            (
            <div></div>
            )
          }

          <div class="player__inner-buttons">
            <button
              class={
                firstTrack
                  ? "button-round button-round--small is-disabled"
                  : "button-round button-round--small"
              }
              aria-label="vorheriger Beitrag"
              onClick={() => props.playerController.prevFeature()}
            >
              <svg class="icon icon--blue">
                <use xlinkHref="#icon-prev"></use>
              </svg>
            </button>
            <button
              class="button-round button-round--big"
              tabindex="0"
              aria-label="Abspielen/Pausieren"
              onClick={() => props.playerController.togglePlaybackStatus()}
            >
              {audioFeatureProps.isPlaying == true ? (
                <PauseIcon />
              ) : (
                <PlayIcon />
              )}
            </button>
            <button
              class={
                lastTrack
                  ? "button-round button-round--small is-disabled"
                  : "button-round button-round--small"
              }
              aria-label="nächster Beitrag"
              onClick={() => props.playerController.nextFeature()}
            >
              <svg class="icon icon--blue">
                <use xlinkHref="#icon-next"></use>
              </svg>
            </button>
          </div>
          <button
            id="toggle-big-player"
            class="button-round button-round--tiny"
            aria-label="Player einklappen"
            onClick={() => props.playerController.toggleMiniPlayer()}
          >
            <svg class="icon icon--blue">
              <use xlinkHref="#icon-arrow-down"></use>
            </svg>
          </button>
        </div>

        <div class="player__meta">
          <div>
            <div class="title" aria-label="Tourentitel">
              {props.playerFeature.currentTrack.name || "Parkanlage"}
            </div>
            {false && (
              <div class="subtitle" aria-label="Stationstitel">
                Sanierung
              </div>
            )}
          </div>
          <div class="track-num">
            <span>{numberOfCurrentTrack || "?"}</span>/
            <span>{props.playingStation.audiofiles.length || "04"}</span>
          </div>
        </div>

        <InputRange
          maxValue={100}
          minValue={0}
          value={audioFeatureProps.progress}
          onChange={(e) =>
            audioFeatureProps.setTime((e / 100) * audioFeatureProps.duration)
          }
        />

        <div class="player__time">
          <div class="player__time-elapsed">
            {currentTimeInMin || "60"}:{currentTimeInSec || "00"}
          </div>
          <div class="player__time-total">
            {durationInMin || "60"}:{durationInSec || "00"}
          </div>
        </div>

        {!noTour &&
          <div class="player__nav">
          <button
            class="button-square player__prev-station"
            onClick={() =>
              props.currentStation.startOfTour
                ? route(props.currentTour.url)
                : route(
                    props.currentTour.url +
                      "/station/" +
                      props.currentStation.idPrev +
                      "/"
                  )
            }
          >
            <svg class="icon icon--blue">
              <use xlinkHref="#icon-arrow-left"></use>
            </svg>
          </button>

          <div class="station-title">
            {props.currentStation.startOfTour && "1. Station"}
            {!props.currentStation.endOfTour &&
            !props.currentStation.startOfTour
              ? "Station " +
                props.currentStation.number +
                "/" +
                props.currentTour.stationsSorted.length
              : props.currentStation.endOfTour && "Letzte Station"}
          </div>

          <button
            class="button-square player__next-station"
            onClick={() => {
              window.scrollTo(0, 0);
              props.currentStation.endOfTour
                ? route(props.currentTour.url)
                : route(
                    props.currentTour.url +
                      "/station/" +
                      props.currentStation.idNext +
                      "/"
                  );
            }}
          >
            <svg class="icon icon--blue">
              <use xlinkHref="#icon-arrow-right"></use>
            </svg>
          </button>
        </div>}
      </aside>
      <aside class="player-mini is-active">
        <div class="player-mini__buttons">
          <button
            class="button-round button-round--big"
            tabindex="0"
            aria-label="Abspielen/Pausieren"
            onClick={() => props.playerController.togglePlaybackStatus()}
          >
            {audioFeatureProps.isPlaying == true ? <PauseIcon /> : <PlayIcon />}
          </button>
        </div>
        <div class="player-mini__mid">
          {/* TITEL UND TRACKNUMMER */}
          <div class="player-mini__meta">
            <div>
              <div class="title" aria-label="Tourentitel">
                {props.playerFeature.currentTrack.name || "Parkanlage"}
              </div>
            </div>
          </div>

          {/* FORTSCHRITTSBALKEN */}
          <div class="player-mini__progress">
            <InputRange
              maxValue={100}
              minValue={0}
              value={audioFeatureProps.progress}
              onChange={(e) =>
                audioFeatureProps.setTime(
                  (e / 100) * audioFeatureProps.duration
                )
              }
            />
          </div>

          {/* ZEITANGABEN */}
          <div class="player-mini__time">
            <div class="player__time-elapsed">
              {currentTimeInMin || "60"}:{currentTimeInSec || "00"}
            </div>
            <div class="player__time-total">
              {durationInMin || "60"}:{durationInSec || "00"}
            </div>
          </div>
        </div>

        <div class="player-mini__toggle">
          {/* PLAYER AUSKLAPPEN */}
          <button
            id="toggle-mini-player"
            class="button-round button-round--tiny"
            aria-label="Player ausklappen"
            onClick={() => props.playerController.toggleMiniPlayer()}
          >
            <svg class="icon icon--blue">
              <use xlinkHref="#icon-arrow-up"></use>
            </svg>
          </button>
        </div>
      </aside>
    </>
  );
};

const PauseIcon = () => (
  <svg class="icon icon--blue">
    <use xlinkHref="#icon-pause"></use>
  </svg>
);
const PlayIcon = () => (
  <svg class="icon icon--blue">
    <use xlinkHref="#icon-play"></use>
  </svg>
);

export default Player;
