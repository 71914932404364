import { h } from "preact";
import { MapContainer } from "react-leaflet";
import "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css";
import "leaflet-defaulticon-compatibility";
import { useEffect, useState } from "preact/hooks";
import "leaflet.locatecontrol";
import "leaflet.locatecontrol/dist/L.Control.Locate.min.css";
import "../../css/bigmap.css";
import "../../css/map.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { MAP_DEFAULTS } from "../../utlis/maputils";
import { Fly } from "../Map/shared/Fly";
import { Locate } from "../Map/shared/Locate";
import { StationMarker } from "./StationMarker";
import { MapLayer } from "./MapLayer";

export const MapBig = (props) => {
  let [locate, setLocate] = useState(true);
  let [action, setAction] = useState(true);
  useEffect(() => {
    setAction(true);
  }, [props.station]);
  if (!MAP_DEFAULTS.ENABLED) {
    return null;
  }
  return (
    <aside style={{ overflow: "unset" }} class="map">
      <div class="sticky">
        {typeof window !== "undefined" &&
          <MapContainer
            style={{ height: window.innerHeight }}
            scrollWheelZoom={false}
            center={MAP_DEFAULTS.CENTER}
            zoom={MAP_DEFAULTS.ZOOM}
            maxZoom={MAP_DEFAULTS.MAXZOOM}
            tap={false}
          >
            <MapLayer />
            <MarkerClusterGroup maxClusterRadius={45}>
              {props.stations?.map((station, i) => (
                <StationMarker
                  key={i}
                  station={station}
                  tours={props.tours}
                />
              ))}
            </MarkerClusterGroup>
            <Fly {...{ ...props, action, setAction }} />
            <Locate {...{ locate, setLocate }} />
          </MapContainer>}
      </div>
    </aside>
  );
};
