import { h } from "preact";
import { useMap } from "react-leaflet";
import L from "leaflet";

export const Locate = (props) => {
  const map = useMap();

  if (props.locate) {
    L.control.locate({ cacheLocation: true }).addTo(map);
    props.setLocate(false);
  }
  return null;
};
